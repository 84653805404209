function createAdLabel(bamAdSlotId, headerHeight) {
  const adLabel = document.createElement("div");
  adLabel.textContent = "Annons";
  adLabel.style.cssText = `
    color: white;
    background-color: rgba(0, 0, 0, 0.50);
    font-size: 16px;
    position: fixed;
    top: ${headerHeight ?? '0'};
    left: 0px;
    font-weight: 700;
    padding: 4px 8px;
    z-index: 1;
    border-radius: 0px 0px 2px 0px;
    backdrop-filter: blur(2px);
  `;

  document.getElementById(bamAdSlotId).appendChild(adLabel);
}

function createArrow(bamAdSlotId) {
  const arrow = document.createElement("div");
  arrow.innerHTML = `
    <svg width="21" height="15" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.6001 0.834973L13.4501 2.70997L7.00007 9.15997L0.550073 2.70997L2.40007 0.834972L7.00007 5.43497L11.6001 0.834973Z" fill="white"/>
    </svg>
  `;
  arrow.style.cssText = `
    position: absolute;
    bottom: 16px;
    width: 52px !important;
    height: 52px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(2px);
    border-radius: 100px;
  `;
  arrow.addEventListener("click", () => {
    const contentStartHeight =
      window.visualViewport.pageTop +
      document.getElementById(bamAdSlotId).getBoundingClientRect().bottom +
      1; //+ 1 to make sure the ad has been scrolled down all the way.

    window.scrollTo({ behavior: "smooth", top: contentStartHeight });
  })
  document.getElementById(bamAdSlotId).appendChild(arrow);
}

function createCloseButton(bamAdSlotId) {
  const closeButton = document.createElement("div");
  closeButton.innerHTML = `
    <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.39999 19.45L4.54999 17.6L10.15 12L4.54999 6.40005L6.39999 4.55005L12 10.15L17.6 4.55005L19.45 6.40005L13.85 12L19.45 17.6L17.6 19.45L12 13.85L6.39999 19.45Z" fill="white"/>
    </svg>
  `;
  closeButton.style.cssText = `
    position: fixed;
    top: 16px;
    right: 16px;
    width: 52px !important;
    height: 52px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(2px);
    border-radius: 100px;
  `;
  closeButton.addEventListener("click", async () => {
    /*
      There is a choice to be made if we decide on removing the google slot from the page or just scroll past.

      1. if we do remove it we should send out a event that the slot has been collapsed and let the site handle it.

      2. Scroll down to under it, this is the current implementation.
    */

    // Option 2.
    const contentStartHeight =
      window.visualViewport.pageTop +
      document.getElementById(bamAdSlotId).getBoundingClientRect().bottom +
      1; //+ 1 to make sure the ad has been scrolled down all the way.

    await new Promise((res) => {
      window.scrollTo({ behavior: "smooth", top: contentStartHeight });

      const scrollEndEvent = window.addEventListener("scrollend", () => {
        window.removeEventListener("scrollend", scrollEndEvent);
        res();
      });
    });

    // Option 1.
    googletag.destroySlots([googletag.pubads().getSlots().find((slot) => slot.getSlotElementId() === bamAdSlotId)]);

    document.getElementById(bamAdSlotId).dispatchEvent(new CustomEvent("bad:collapse", {
      bubbles: true,
      detail: {
        slotName: bamAdSlotId.split("-")[0],
        slotNumber: bamAdSlotId.split("-")[1],
        id: bamAdSlotId
      }
    }));

    document.querySelector("[data-bad-fullpage-container]").remove();
  });
  document.getElementById(bamAdSlotId).appendChild(closeButton);
}

function isAncestor(childWindow, frameWindow) {
  if (frameWindow === childWindow) {
    return true;
  } else if (childWindow === window.top) {
    return false;
  }
  return childWindow?.parent
    ? isAncestor(childWindow.parent, frameWindow)
    : null;
}

function getFullPageHeaderElement() {
  const selectors = [
    "[data-bad-fullpage-header]"
  ];
  return selectors
    .map((selector) => document.querySelector(selector))
    .find((element) => element);
}

function getHeaderElement() {
  const selectors = [
    ".site-header__wrapper",
    ".site-header__row-1",
    "#navbar",
    ".sticky-header",
    ".sticky-menu",
    "#menu-sm",
    ".header-grid",
    ".site-header > div",
    "[data-site-header]"
  ];
  return selectors
    .map((selector) => document.querySelector(selector))
    .find((element) => element);
}

function getBottomElement() {
  const selectors = [".sticky.bottom-0"];
  return selectors
    .map((selector) => document.querySelectorAll(selector)[0])
    .find((element) => element !== null);
}

function checkVisible(elm) {
  try {
    const rect = elm.getBoundingClientRect();
    const viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );

    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
  } catch (e) {
    return false;
  }
}

function getHeaderHeight() {
  const header = getHeaderElement();
  if (!header) return "0px";
  const headerHeight = header.offsetHeight;
  const afterStyle = getComputedStyle(header, "::after");
  const afterHeight = parseFloat(afterStyle.height) || 0;
  return `${headerHeight + afterHeight}px`;
}

function seenThisPlugin(event) {
  if (!event.data) return;

  let data, bamAdSlot, adIframe;

  try {
    data = JSON.parse(event.data);

    const frames = document.querySelectorAll('iframe[id*="google_ads_iframe"]');
    adIframe = Array.from(frames).find((frame) =>
      isAncestor(event.source, frame.contentWindow)
    );

    bamAdSlot = adIframe.closest("b-a-d");
  } catch (e) {
    return;
  }

  if (!adIframe || !bamAdSlot) return;

  if (data.action === "responsive") {
    const adIframeId = adIframe.id;
    const responsiveStyle = document.createElement("style");
    responsiveStyle.innerHTML = `
      [slotname='rich_media_premium'] [id*='${adIframeId}'],
      [slotname='mob_rich_media_premium'] [id*='${adIframeId}'] {
        width: 100% !important;
        height: 100% !important;
        transform: none !important;
      }

      [slotname='rich_media_premium'], [slotname='mob_rich_media_premium'] {
        height: 100% !important;
      }
    `;
    document.head.appendChild(responsiveStyle);
  } else if (data.highImpactRequest) {
    const bamAdSlotToHighImpactFormats = {
      mob: ["midscroll", "mobile_mega"],
      mob_rich_media_premium: ["topscroll", "topscroll_gaming"],
      rich_media_premium: ["topscroll", "topscroll_gaming"],
    };

    const adIframeId = adIframe.id;
    const bamAdSlotId = bamAdSlot.id;

    if (adIframeId === "" || bamAdSlotId === "") return;

    if (data.action === "highImpactSupportedFormat") {
      event.source.postMessage(
        JSON.stringify({
          action: bamAdSlotToHighImpactFormats[bamAdSlot.slotName],
        }),
        "*"
      );
    } else if (data.action === "midscroll") {
      const adWrapper = document.getElementById(bamAdSlotId);
      const boundingClientRect = adWrapper.parentElement.getBoundingClientRect();
      const wrapperLeftMargin = window.getComputedStyle(adWrapper).marginLeft;
      let leftMargin = boundingClientRect.left - parseInt(wrapperLeftMargin, 10);

      const styles = getComputedStyle(document.documentElement);

      let headerHeight = styles.getPropertyValue('--bad-site-header-stuck-height').trim();

      if (headerHeight === "") {
        headerHeight = getHeaderHeight();
      }

      if (window.bamData.path.includes("/bbm/")) {
        headerHeight = getHeaderElement()?.firstChild?.offsetHeight + "px";
      }

      const midscrollStyle = document.createElement("style");

      if (!checkVisible(getHeaderElement())) {
        headerHeight = "0px";
      }

      if (
        window.bamData.path.includes("/bn/hdsyd") ||
        window.bamData.path.includes("/mm/") ||
        window.bamData.path.includes("/hm/") ||
        window.bamData.path.includes("/hbl/") ||
        window.bamData.path.includes("/gotamedia/")
      ) {
        midscrollStyle.textContent = `
          .ad-text:has(+ #${bamAdSlotId}) {
            display: none !important;
          }
        `;
        adWrapper.parentElement.style.cssText = `
          margin: 0px !important;
          transform: none !important;
          contain: none !important;
        `;
      }

      const bottomElement = getBottomElement();
      const bottomHeight = bottomElement ? bottomElement.offsetHeight : 0;

      const labelHeight = `calc(100vh - ${headerHeight} - ${bottomHeight}px)`;

      // Uncomment this to find the header height instead.
      // const headerHeight = `${[".site-header__wrapper", ".site-header__row-1", "#navbar", ".sticky-header", ".sticky-menu", "#menu-sm", ".header-grid", ".site-header > div"].map((e => document.querySelector(e))).find((e => null !== e))?.offsetHeight || 0}px`;

      midscrollStyle.textContent += `
        [slotname='mob']:has(>#${bamAdSlotId}) {
          width: 100vw !important;
          margin-left: -${leftMargin}px;
        }

        div #${bamAdSlotId} {
          height: ${labelHeight} !important;
          clip-path: polygon(0px 0px, 100vw 0px, 100vw ${labelHeight}, 0px ${labelHeight}) !important;
          margin: 0px 0px 0px 0px !important;
          padding: 0px !important;
          position: relative !important;
          width: 100vw !important;
          transform: none !important;
        }

        [id='${bamAdSlotId}'] [id*='${adIframeId}'], [id='${bamAdSlotId}'] [id*='${adIframeId}'] {
          height: calc(100vh - ${headerHeight} - ${bottomHeight}px) !important;
          width: 100vw !important;
          clip: rect(auto, auto, auto, auto) !important;
          position: fixed !important;
          left: 0px !important;
          top: ${headerHeight} !important;
          transform: none !important;
        }

        [id='${bamAdSlotId}'] [class*='ad-info_button'] {
          display: none !important;
        }
      `

      document.head.appendChild(midscrollStyle);

      createAdLabel(bamAdSlotId, headerHeight);
    } else if (data.action.includes("topscroll")) { // Includes both 'topscroll' and 'topscroll_gaming'
      // const adWrapper = document.getElementById(bamAdSlotId);
      const topScrollStyle = document.createElement("style");
      let adSlotMinHeight = "0px"; // Fallback

      if (data.action === "topscroll") {
        const styles = getComputedStyle(document.documentElement);


        let headerHeight = styles.getPropertyValue('--bad-fullpage-header-height').trim();

        if (headerHeight === "") {
          headerHeight = styles.getPropertyValue('--bad-site-header-height').trim();
        }

        if (headerHeight) {
          adSlotMinHeight = `calc(100svh - ${headerHeight})`;
        } else {
          adSlotMinHeight = "100svh";
        }
      } else if (data.action === "topscroll_gaming") {
        adSlotMinHeight = "70svh";
      }

      topScrollStyle.textContent = `
        [data-bad-fullpage-container], [data-bad-fullpage-adslot] {
          height: ${adSlotMinHeight} !important;
        }

        [data-bad-fullpage-header] {
          display: none !important;
          visibility: hidden;
          height: 0;
          width: 0;
          overflow: hidden;
          padding: 0 !important;
        }

        div #${bamAdSlotId}, #rich-media-wrapper {
          height: ${adSlotMinHeight} !important;
          clip-path: polygon(0px 0px, 100vw 0px, 100vw ${adSlotMinHeight}, 0px ${adSlotMinHeight}) !important;
          margin: 0px !important;
          padding: 0px !important;
          position: relative !important;
          --ad-slot-minheight: none;
          width: 100vw !important;
        }

        [slotname='rich_media_premium'] [id*='${adIframeId}'],
        [slotname='mob_rich_media_premium'] [id*='${adIframeId}'] {
          height: ${adSlotMinHeight} !important;
          width: 100vw !important;
          clip: rect(auto, auto, auto, auto) !important;
          position: fixed !important;
          left: 0px !important;
          transform: none !important;
        }
      `
      document.head.appendChild(topScrollStyle);

      createAdLabel(bamAdSlotId);
      createArrow(bamAdSlotId);
      createCloseButton(bamAdSlotId);
    } else if (data.action === "mobile_mega") {
      const adWrapper = document.getElementById(bamAdSlotId);

      const boundingClientRect = adWrapper.parentElement.getBoundingClientRect();
      const wrapperLeftMargin = window.getComputedStyle(adWrapper).marginLeft;
      let leftMargin = boundingClientRect.left - parseInt(wrapperLeftMargin, 10);

      const mobileMegaStyle = document.createElement("style");

      mobileMegaStyle.textContent = `
        [slotname='mob']:has(>#${bamAdSlotId}) {
          width: 100vw !important;
          margin-left: -${leftMargin}px;
        }

        div:has(> [id='${adIframeId}']) {
          width: 100% !important;
          height: 568px !important;
        }

        [id='${adIframeId}'] {
          width: 100% !important;
          height: 568px !important;
        }

        #${bamAdSlotId} {
          width: 100vw !important;
        }
      `;

      document.head.appendChild(mobileMegaStyle);
    }
  }
}

export default () => {
  window.addEventListener("message", (event) => {
    seenThisPlugin(event);
  });
};

export {
  createAdLabel,
  createArrow,
  createCloseButton,
  isAncestor,
  getFullPageHeaderElement,
  getHeaderElement,
  getHeaderHeight,
  getBottomElement,
  checkVisible,
  seenThisPlugin,
};
