// @ts-check
import resolvePoofWidget from "#assets/scripts/api/resolve-poof-widget.js";

export const TAG_NAME = "gen-poof-widget";

/* eslint-disable es-x/no-class-fields */
export class PoofWidget extends HTMLElement {
  /** @type {{ id: string, contentId: string, margin: string, threshold: number }} */
  // @ts-ignore
  props;
  /** @type {{ isLoading: boolean, loadedWidget: boolean }} */
  // @ts-ignore
  state;
  /** @type {IntersectionObserver} */
  // @ts-ignore
  observer;

  constructor() {
    super();

    this.initObserver = this.initObserver.bind(this);
    this.handleOnObserve = this.handleOnObserve.bind(this);
    this.handlePoof = this.handlePoof.bind(this);
  }

  connectedCallback() {
    this.initProps();
    this.initState();
    this.initObserver();
  }

  /**
   * Initiate the props object from parsed attributes
   */
  initProps() {
    this.props = {
      id: this.getAttribute("id") || "",
      contentId: this.getAttribute("contentid") || "",
      margin: this.getAttribute("margin") || "100px",
      threshold: Number(this.getAttribute("threshold")) || 1.0,
    };

    if (!(this.props.id && this.props.contentId)) {
      throw new Error(`${TAG_NAME} | missing required "${this.props.id ? "id" : "contentid"}" prop`);
    }
  }

  /**
   * Initiate the state object
   */
  initState() {
    this.state = {
      isLoading: false,
      loadedWidget: false,
    };
  }

  /**
   * Initiate observer and start observing
   */
  initObserver() {
    this.observer = new IntersectionObserver(this.handleOnObserve, {
      rootMargin: this.props.margin,
      threshold: this.props.threshold,
    });

    this.observer.observe(this);
  }

  /**
   * Observer callback to to handle calling poof handler
   * @param {IntersectionObserverEntry[]} entries
   * @returns
   */
  async handleOnObserve(entries) {
    const entry = entries[0];

    if (!(entry instanceof IntersectionObserverEntry)) {
      return;
    }

    if (
      entry.isIntersecting &&
      !this.state.isLoading
    ) {
      if (!this.state.loadedWidget) {
        this.state.isLoading = true;

        this.classList.add("spinner");
        await this.handlePoof();
        this.classList.remove("spinner");

        this.state.isLoading = false;
        this.state.loadedWidget = true;
        this.observer.unobserve(this);
      }
    }
  }

  /**
   * Handle fetching poof content and append HTML response into the document
   */
  async handlePoof() {
    try {
      const teasersDocument = await resolvePoofWidget({
        id: this.props.id,
        contentId: this.props.contentId,
        path: new URL(window.location.href).pathname,
      });

      if (teasersDocument) {
        const newContent = document
          .createRange()
          .createContextualFragment(teasersDocument);

        this.appendChild(newContent);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
}
