// @ts-check

/**
 * @param {{ id: string, path: string, contentId: string }} param
 * @returns {Promise<string | undefined>}
 */
const resolvePoofWidget = async ({ id, path, contentId }) => {
  const searchParams = new URLSearchParams({ id, path, contentId });

  const response = await fetch(`/api/resolve-poof-content/?${searchParams.toString()}`);

  if (response.ok) {
    return await response.text();
  }

  return;
};

export default resolvePoofWidget;
