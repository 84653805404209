import { css, LitElement } from "lit";
import getContextConfig from "../helpers/get-context-config";
import staticSlotSizes from "../static/cookie-less-ads-slot-sizes";
import badEvents from "../static/bad-events";
import { getSlotNumber } from "../helpers/get-slot-number";
import { getCookie } from "../helpers/cookie-helper";

const config = getContextConfig();

const intersectionObserver = new IntersectionObserver(
  (entries) => {
    entries
      .filter((entry) => entry.isIntersecting || entry.intersectionRatio > 0)
      .forEach((entry) => {
        entry.target.display();
        intersectionObserver.unobserve(entry.target);
      });
  },
  {
    rootMargin: "10%",
  }
);

export default class BNAdElement extends LitElement {
  static styles = css`
    div {
      border: 1px solid blue;
    }
  `;

  static properties = {
    slot: { state: true },
    anchor: { state: true },
    image: { state: true },
    slotName: { type: String },
    slotNumber: { type: Number },
    slotSizes: {
      type: Array,
      converter: {
        fromAttribute: (value) => value.split(/,\s*/g).map((size) => [parseInt(size.split("x")[0]), parseInt(size.split("x")[1])]),
        toAttribute: (value) => value.map(([x, y]) => `${x}x${y}`).join(","),
      },
    },
    targetings: { type: Object },
    targetingTa: {
      type: String,
      attribute: "targeting-ta",
    }
  };

  constructor() {
    super();

    this.anchor = document.createElement("a");
    this.anchor.target = "_blank";

    this.image = document.createElement("img");

    this.image.onload = this.setImageSize();
  }

  connectedCallback() {
    super.connectedCallback();

    const { slotNameNumber, slotNameNumberTargeting } = getSlotNumber(this.slotName, this.slotNumber);

    this.id = `${this.slotName}-${slotNameNumber}`;
    this.slotNumber = slotNameNumberTargeting;
    this.pos = `${this.slotName}${this.slotNumber}`;

    // logic for rich_media_premium & mob_rich_media_premium
    if (this.slotName === "rich_media_premium" || this.slotName === "mob_rich_media_premium") {
      if (getCookie("bad-freq-rmp")) {
        return this.disconnectedCallback();
      }

      // set cookie according to the expire time or 4 hours.
      if (window.bamData.frequencyCookie?.expire) document.cookie = `bad-freq-rmp=1; max-age=${window.bamData.frequencyCookie.expire * 60 * 60}; path=/`;
      else document.cookie = `bad-freq-rmp=1; max-age=${4 * 60 * 60}; path=/`;
    }

    intersectionObserver.observe(this);

    this.appendChild(this.anchor).appendChild(this.image);
  }

  getTargetingString(o) {
    return encodeURIComponent(
      Object.keys(o)
        .filter((k) => !!(k && o[k]))
        .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(Array.isArray(o[k]) ? o[k].join(",") : o[k])}`)
        .join("&")
    );
  }

  setImageSize() {
    return ({ target }) => {
      const { naturalWidth: width, naturalHeight: height } = target;

      // Currently not setting this to make the content flexable and be put in the middle of the screen.
      // this.style.width = `${width}px`;
      this.style.height = `${height}px`;

      this.image.style.width = width;
      this.image.style.height = height;
    };
  }

  _slotRenderEnded(event) {
    // console.log("slotRenderEnded", event);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    if (this.slot) this.slot.style.display = "none";
    if (this.image) this.image.style.display = "none";
  }

  createRenderRoot() {
    return this;
  }

  render() {
    // default style for ads that has no css on them or the parent.
    if (this.parentElement.style.justifyContent === "" && this.style.justifyContent === "") {
      this.style.justifyContent = "center";
    }
    return this.firstChild;
  }

  display() {
    this.style.display = "flex";
    this.style.border = "0";

    const slotConfig = bamData.slotNameConfig[this.slotName];
    if (!slotConfig) return this.disconnectedCallback();

    // if a size is not provided on the slot, we use the default size for that slot.
    const slotSizes = this.slotSizes || slotConfig.slots[this.slotNumber - 1]?.sizes || staticSlotSizes[this.slotName];

    // TODO: Discuss how we should do this to get all the right targertings for C.L.A.S ads.
    const slotTargetings = { _ta_: config?._ta_ || this.targetingTa};

    // Get the custom query targetings from the url.
    const urlParams = new URLSearchParams(window.location.search);

    const queryTargetings = {}
    urlParams.forEach((key, value) => (queryTargetings[value] = key ));

    const targeting = this.getTargetingString({
      ...slotTargetings,
      ...queryTargetings,
      slotName: this.slotName,
      slotNameNo: this.slotNumber,
      tcf: "0",
    });

    const correlationId = Math.random().toFixed(10).split(".").pop();

    const sz = encodeURIComponent(slotSizes.map(([width, height]) => `${width}x${height}`).join("|"));
    this.anchor.href = `${process.env.PRIVAXY_URL}/gampad/jump?iu=${bamData.path}&sz=${sz}&t=${targeting}&c=${correlationId}&tile=${this.slotNumber}&pre=1&d_imp=1&d_imp_hdr=1`;

    fetch(`${process.env.PRIVAXY_URL}/gampad/ad?iu=${bamData.path}&sz=${sz}&t=${targeting}&c=${correlationId}&tile=${this.slotNumber}&pre=1&d_imp=1&d_imp_hdr=1`)
      .then((res) => res.json())
      .then((data) => {
        this.image.src = `${process.env.PRIVAXY_URL}${data.imageUrl}`;
        new Image().src = `${process.env.PRIVAXY_URL}${data.delayedImpressionTrackingUrl}`;

        this.dispatchEvent(new CustomEvent(badEvents.CLAS_SLOT_RENDER_ENDED, {
          bubbles: true,
          detail: {
            slotName: this.slotName,
            slotNumber: this.slotNumber,
            size: slotSizes,
            adUnit: bamData.path,
          }
        }));
      })
      .catch((err) => {
        console.warn(`C.L.A.S could not load ad: ${this.slotName}-${this.slotNumber} inform @au on slack`)

        // Dispatch an event for the site to handle their ad div.
        this.dispatchEvent(new CustomEvent(badEvents.COLLAPSE, {
          bubbles: true,
          detail: {
            slotName: this.slotName,
            slotNumber: this.slotNumber,
          }
        }));

        this.style.display = "none"
      });
  }
}
