import getAdPositionFromScreenshot from "./helpers/get-ad-position-from-screenshot";
import { isLoggedIn } from "../helpers/publisher-provided-id";
import {
  adInfoButtonCss,
  getAdInfoDialog,
  getAdInfoDialogCss,
  getAdInfoReportDialog,
  getReportSuccessDialog,
} from "./static/ad-info";
import getAdInfoCss from "./static/ad-info-css";

const tintin = {
  URL: `${process.env.AD_REPORTER_URL}/report-ad`,
};

function sendTintinAdSlotReport(data, div, slot) {
  showConfirmation(div, slot);

  fetch(tintin.URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (!res.ok) throw new Error("Failed to report ad");
    })
    .catch((error) => {
      console.error("", error);
    });
}

function showConfirmation(div, slot) {
  let dialogModal;
  if (!document.getElementById("dialog-report-success-modal")) {
    dialogModal = document.createElement("dialog");
    dialogModal.innerHTML = getReportSuccessDialog();
    document.body.appendChild(dialogModal);

    const dialogReportButton = document.getElementById(
      "dialog-success-report-button"
    );
    const dialogCancelButton = document.getElementById(
      "dialog-success-cancel-button"
    );

    dialogModal.id = "dialog-report-success-modal";
    dialogModal.className = "ad-info_dialog-modal";
    dialogModal.addEventListener("click", (event) => {
      if (event.target.id === "dialog-report-success-modal")
        dialogModal.close();
    });
    dialogReportButton.onclick = () => dialogModal.close();
    dialogCancelButton.onclick = () => dialogModal.close();
  }
  dialogModal = document.getElementById("dialog-report-success-modal");
  dialogModal.showModal();

  const adInfoButton = div.getElementsByClassName("ad-info_button")[0];
  const { height: adHeight, width: adWidth } = window.getComputedStyle(div);

  slot.setCollapseEmptyDiv(false);
  window.googletag.destroySlots([slot]);
  div.innerHTML = `
    <div style="height: ${adHeight}; padding: 64px 40px 64px 40px; box-sizing: border-box; width: ${adWidth}; background-color: rgb(211,211,211,1); z-index: 400; font-family: 'inter V', sans-serif; max-width: 1000px; max-height: 70vh; min-height: 380px;">
    <h2>Annonsen är rapporterad</h2>
    <p>Vi ser över den rapporterade  annonsen så snart som möjligt. Tills dess kan annonsen synas på andra artiklar. Den behöver då inte rapporteras igen.</p>
    <p>Tack för ditt deltagande!</p>
    </div>
  `;
  div.onclick = () => {};
  adInfoButton.remove();
}

function sleepms(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

function getScreenshots(callback) {
  navigator.mediaDevices.getDisplayMedia({ preferCurrentTab: true }).then((mediaStream) => {
    // sleep time expects milliseconds
    // must sleep 150ms. If only 100ms we get the sharescreen popup in picture.
    sleepms(150).then(() => {
      const mediaTracks = mediaStream.getVideoTracks();

      const framePromises = mediaTracks.map((track) => {
        const imageCaptureInstance = new ImageCapture(track);
        const frame = imageCaptureInstance.grabFrame();

        return frame;
      });
      Promise.all(framePromises).then((frames) => {
        // stop recording
        mediaTracks.forEach((track) => {
          track.stop();
        });
        return callback(frames);
      });
    });
  })
  .catch((error) => {
    callback(null, error);
  });
}

function submitReportHandler(params, div, slot) {
  if ("ImageCapture" in window && params.type === "AdX") {
    let oldStyle;
    if (
      div.parentNode.tagName === "MAIN" ||
      div.parentNode.classList.contains("site-body__columns") ||
      div.parentNode.classList.contains("outsider-ads__sticky-context") ||
      div.parentNode.classList.contains("outsider-ad") ||
      div.parentNode.classList.contains("site-body__column-3")
    ) {
      oldStyle = div.style;
      div.style.border = "1rem solid rgb(141, 10, 231, 1)";
      div.style.padding = "0.4rem 0";
    } else if (
      div.parentNode.classList.contains("ad-fullpage") ||
      div.parentNode.classList.contains("fullpage-ad") ||
      div.parentNode.classList.contains("slot-rich_media_premium") ||
      div.parentNode.classList.contains("ad--rich_media_premium")
    ) {
      oldStyle = div.firstChild.style;
      div.firstChild.style.border = "1rem solid rgb(141, 10, 231, 1)";
      div.firstChild.style.padding = "0.4rem 0";
    } else {
      oldStyle = div.parentNode.style;
      div.parentNode.style.border = "1rem solid rgb(141, 10, 231, 1)";
      div.parentNode.style.padding = "0.4rem 0";
    }

    getScreenshots((screenshots, error) => {
      if (error) console.error(error);
      if (screenshots) {
        const screenshotURLs = screenshots.map((screenshot) => {
          const screenCanvas = document.createElement("canvas");
          screenCanvas.width = screenshot.width;
          screenCanvas.height = screenshot.height;
          const screenContext = screenCanvas.getContext("2d");

          screenContext.drawImage(screenshot, 0, 0);

          const { topLeft, bottomRight } = getAdPositionFromScreenshot(
            screenshot,
            screenContext,
            screenCanvas
          );

          if (
            topLeft.x &&
            topLeft.y &&
            bottomRight.x &&
            bottomRight.y &&
            bottomRight.x > topLeft.x &&
            bottomRight.y > topLeft.y
          ) {
            const adImageData = screenContext.getImageData(
              topLeft.x,
              topLeft.y,
              bottomRight.x - topLeft.x,
              bottomRight.y - topLeft.y
            );
            const adCanvas = document.createElement("canvas");
            adCanvas.width = bottomRight.x - topLeft.x;
            adCanvas.height = bottomRight.y - topLeft.y;

            const adContext = adCanvas.getContext("2d");
            adContext.putImageData(adImageData, 0, 0);
            return adCanvas.toDataURL();
          }
          return "";
        });
        params.screenshotURLs = screenshotURLs;
        if (div.parentNode.style.border === "1rem solid rgb(141, 10, 231)") {
          div.parentNode.style = oldStyle;
        } else if (
          div.children[0].style.border === "1rem solid rgb(141, 10, 231)"
        ) {
          div.children[0].style = oldStyle;
        } else {
          div.style = oldStyle;
        }
        sendTintinAdSlotReport(params, div, slot);
      } else {
        // handle error. The user has not allowed screenshots. We don't need to do anything. We don't send the report.
        if (div.parentNode.style.border === "1rem solid rgb(141, 10, 231)") {
          div.parentNode.style = oldStyle;
        } else if (
          div.children[0].style.border === "1rem solid rgb(141, 10, 231)"
        ) {
          div.children[0].style = oldStyle;
        } else {
          div.style = oldStyle;
        }
      }
    });
  } else {
    sendTintinAdSlotReport(params, div, slot);
  }
}

function reportButtonClick(params, div, slot) {
  let dialogModal;
  if (!document.getElementById("dialog-report-modal")) {
    dialogModal = document.createElement("dialog");
    dialogModal.innerHTML = getAdInfoReportDialog();
    document.body.appendChild(dialogModal);

    dialogModal.id = "dialog-report-modal";
    dialogModal.className = "ad-info_dialog-modal";
    dialogModal.addEventListener("click", (event) => {
      if (event.target.id === "dialog-report-modal") dialogModal.close();
    });
    const dialogCancelButton = document.getElementById(
      "dialog-report-cancel-button"
    );
    dialogCancelButton.onclick = () => dialogModal.close();
  }
  dialogModal = document.getElementById("dialog-report-modal");

  const dialogReportForm = document.getElementById("dialog-report-form");
  dialogReportForm.onsubmit = () => {
    // formMethod = dialog closes the dialog when the form is submitted.
    params.reason = dialogModal.querySelector(
      "input[name=reason]:checked"
    ).value;
    submitReportHandler(params, div, slot);
  };

  const screenshotInfoText = document.getElementById(
    "ad-info_dialog_screenshot_info"
  );
  const reportButton = document.getElementById("dialog-report-submit-button");

  if ("ImageCapture" in window && params.type === "AdX") {
    screenshotInfoText.hidden = false;
    reportButton.textContent = "Nästa";
  } else {
    screenshotInfoText.hidden = true;
    reportButton.textContent = "Skicka";
  }
  dialogModal.showModal();
}

function getHtmlForAd(id, type, slot) {
  if (type === "Prebid") {
    const bid = pbjs
      .getAllWinningBids()
      .find((winningBid) => winningBid.adUnitCode === id);
    return bid && bid.ad;
  } else if (type === "IO") {
    return slot.getHtml();
  }
}

function gatherInformation(event, div, cb) {
  const slot = event.slot;
  const responseInformation = slot.getResponseInformation();

  const adInfo = {
    id: slot.getSlotElementId(),
    campaignId: event.campaignId,
    creativeId: event.creativeId,
    lineItemId: event.lineItemId,
    device: "unknown",
    googleQueryId: div.dataset.googleQueryId,
    path: slot.getAdUnitPath(),
    size: `${div.width || 0}x${div.height||0}`,
  };

  if (window?.pbjs?.cmd) {
    setTimeout(() => {
      if (responseInformation?.isBackfill) {
        adInfo.type = "AdX";
        adInfo.creativeId = responseInformation.sourceAgnosticCreativeId;
        adInfo.lineItemId = responseInformation.sourceAgnosticLineItemId;
        adInfo.yieldGroupId =
        responseInformation.yieldGroupIds && responseInformation.yieldGroupIds[0];
      } else if (pbjs?.getAllWinningBids().find((bid) => bid.adUnitCode === adInfo.id)) {
        adInfo.type = "Prebid";
        adInfo.prebidData = pbjs.getAllWinningBids().find((bid) => bid.adUnitCode === adInfo.id);
      } else {
        adInfo.type = "IO";
      }

      cb(adInfo);
    }, 50);
  } else {
    if (responseInformation.isBackfill) {
      adInfo.type = "AdX";
      adInfo.creativeId = responseInformation.sourceAgnosticCreativeId;
      adInfo.lineItemId = responseInformation.sourceAgnosticLineItemId;
      adInfo.yieldGroupId =
      responseInformation.yieldGroupIds && responseInformation.yieldGroupIds[0];
    } else {
      adInfo.type = "IO";
    }

    cb(adInfo);
  }
}

function adInfoClick(params, div, slot) {
  params.retrievedAt = new Date().toUTCString();
  params.url = window.location.href;
  params.adhtml = getHtmlForAd(params.id, params.type, slot);

  div.scrollIntoView({ behavior: "smooth", block: "center" });
  let dialogModal;

  if (!document.getElementById("dialog-modal")) {
    dialogModal = document.createElement("dialog");
    dialogModal.innerHTML = getAdInfoDialog();

    dialogModal.id = "dialog-modal";
    dialogModal.className = "ad-info_dialog-modal";
    dialogModal.ariaLabel = "Annonsinformation och rapportering";
    dialogModal.ariaDescription = "Annonsinformation och rapportering";

    dialogModal.addEventListener("click", (event) => {
      if (event.target.id === "dialog-modal") dialogModal.close();
    });

    document.body.appendChild(dialogModal);
    const dialogCancelButton = document.getElementById("dialog-cancel-button");
    const reportSection = document.getElementById(
      "ad-info_dialog-section-report"
    );

    dialogCancelButton.onclick = () => dialogModal.close();

    if (!isLoggedIn()) reportSection.style.display = "none";
  }
  dialogModal = document.getElementById("dialog-modal");
  const dialogReportButton = document.getElementById("dialog-report-button");

  dialogReportButton.onclick = () => {
    dialogModal.close();
    reportButtonClick(params, div, slot);
  };
  dialogModal.showModal();
}

function setupTintinReportButton(evt) {
  const details = evt.detail;
  const div = evt.target;

  if (details.isEmpty || !div) return;

  const computedStyle = window.getComputedStyle(div);
  if (computedStyle.position === "" || computedStyle.position === "static") {
    div.style.position = "relative";
  }

  gatherInformation(details, div, (adInfo) => {
    const adInfoButton = document.createElement("button");
    adInfoButton.className = "ad-info_button";
    adInfoButton.title = "Visa annonsinformation";
    adInfoButton.type = "button";
    adInfoButton.ariaLabel = "Visa annonsinformation";
    adInfoButton.ariaDescription = "Visa annonsinformation och rapportera annons";
    // adInfoButton svg is circle with an exclamation mark.
    adInfoButton.innerHTML =
      '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="currentColor"><path d="M8.73 13.56A1.13 1.13 0 0 0 9.9 12.4c0-.34-.12-.62-.34-.85-.22-.23-.5-.34-.83-.34-.33 0-.61.11-.83.34-.23.23-.34.51-.34.85a1.13 1.13 0 0 0 1.17 1.16Zm-1.1-3.7h2.2V4.57h-2.2v5.27Zm1.1 7.87a8.48 8.48 0 0 1-3.42-.69A8.84 8.84 0 0 1 .7 12.42 8.48 8.48 0 0 1 0 9 8.84 8.84 0 0 1 5.31.96 8.48 8.48 0 0 1 8.73.27a8.85 8.85 0 0 1 8.04 5.31c.46 1.06.69 2.2.69 3.42a8.85 8.85 0 0 1-5.31 8.04c-1.06.46-2.2.69-3.42.69Zm0-2.2a6.3 6.3 0 0 0 4.63-1.9A6.3 6.3 0 0 0 15.25 9a6.3 6.3 0 0 0-1.89-4.64 6.3 6.3 0 0 0-4.63-1.88 6.3 6.3 0 0 0-4.64 1.88A6.3 6.3 0 0 0 2.21 9a6.3 6.3 0 0 0 1.88 4.64 6.3 6.3 0 0 0 4.64 1.88Z"/></svg>';
    adInfoButton.onclick = () => adInfoClick(adInfo, div, details.slot);

    div.appendChild(adInfoButton);
  })
}

export default () => {
  const styleTag = document.createElement("style");
  const styleDialogTag = document.createElement("style");

  const cssString = getAdInfoCss(bamData?.adInfo?.text || "");
  const cssDialogString = getAdInfoDialogCss();

  styleTag.appendChild(document.createTextNode(cssString));
  styleDialogTag.appendChild(document.createTextNode(cssDialogString));

  window.document.head.appendChild(styleTag);
  window.document.head.appendChild(styleDialogTag);

  adInfoButtonCss();

  window.addEventListener("bad:slotRenderEnded", setupTintinReportButton);
};
